import React from 'react';

const Doubt = () => {
  return (
    <div>
      <h4>Doubt Content</h4>
      <p>This section contains doubts raised by students.</p>
    </div>
  );
};

export default Doubt;
