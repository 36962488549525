import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginTeacher from '../All Components/Teacher Login/LoginTeacher';
import TeacherHome from '../All Components/Teacher Part/TeacherHome';
import ChapterDetails from '../All Components/Teacher Part/ChapterDetails';

const RouteConfigs = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<LoginTeacher />} />
      <Route path="/loginteacher" element={<LoginTeacher />} />
      <Route path="/teacherhome" element={<TeacherHome />} />
      <Route path="/chapterdetails/:suCode/:boardCode/:classCode/:subjectCode" element={<ChapterDetails />} />
    </Routes>
    </BrowserRouter>
  )
}

export default RouteConfigs