import React from 'react';

const Exam = () => {
  return (
    <div>
      <h4>Exam Content</h4>
      <p>This section contains information about upcoming exams.</p>
    </div>
  );
};

export default Exam;
