import React from 'react';

const Assignment = () => {
  return (
    <div>
      <h4>Assignment Content</h4>
      <p>This section contains assignments given to students.</p>
    </div>
  );
};

export default Assignment;
