import React from 'react';
import Profile from './Profile';
import Class from './Class';
import HomeWork from './HomeWork';
import Doubt from './Doubt';
import Calendar from './Calendar';
import Assignment from './Assignment';
import Exam from './Exam';
import LeaveApplication from './LeaveApplication';

const Content = ({ selectedMenu }) => {
  const renderContent = () => {
    switch (selectedMenu) {
      case 'Profile':
        return <Profile />;
      case 'Class':
        return <Class />;
      case 'Home Work':
        return <HomeWork />;
      case 'Doubt':
        return <Doubt />;
      case 'Calendar':
        return <Calendar />;
      case 'Assignment':
        return <Assignment />;
      case 'Exam':
        return <Exam />;
      case 'Leave Application':
        return <LeaveApplication />;
      default:
        return <div>Select a menu option</div>;
    }
  };

  return (
    <div className="content">
      {renderContent()}
    </div>
  );
};

export default Content;
