import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Css(All)/Class.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Class = () => {
  const [boards, setBoards] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [centreDetails, setCentreDetails] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); 

  const navigate = useNavigate(); 

  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
const teacherName = userDetails ? userDetails.TeacherName : 'Teacher';

  const removeDuplicateBoards = (boards) => {
    const uniqueBoards = [];
    const seenBoardNames = new Set();
    boards.forEach((board) => {
      if (!seenBoardNames.has(board.BoardName)) {
        seenBoardNames.add(board.BoardName);
        uniqueBoards.push(board);
      }
    });
    return uniqueBoards;
  };

  const removeDuplicateClasses = (classes) => {
    const uniqueClasses = [];
    const seenClassNames = new Set();
    classes.forEach((classItem) => {
      if (!seenClassNames.has(classItem.ClassName)) {
        seenClassNames.add(classItem.ClassName);
        uniqueClasses.push(classItem);
      }
    });
    return uniqueClasses;
  };

  useEffect(() => {
    axios
      .post(`${API_BASE_URL}/api/boards`)
      .then((response) => {
        let fetchedBoards = Array.isArray(response.data) ? response.data : response.data.data || [];
        const uniqueBoards = removeDuplicateBoards(fetchedBoards);
        setBoards(uniqueBoards);
      })
      .catch((error) => {
        console.error("Error fetching boards:", error);
      });
  }, []);

  const fetchClasses = (boardCode) => {
    if (selectedBoard === boardCode) {
      setClasses([]);
      setSelectedBoard(null);
      setSelectedClass(null);
      setSubjects([]);
    } else {
      axios
        .post(`${API_BASE_URL}/api/classes`, { boardCode })
        .then((response) => {
          let fetchedClasses = removeDuplicateClasses(response.data);
          setClasses(fetchedClasses);
          setSelectedBoard(boardCode);
          setSelectedClass(null);
          setSubjects([]);
        })
        .catch((error) => {
          console.error("Error fetching classes:", error);
        });
    }
  };

  const fetchSubjects = (classCode) => {
    if (selectedClass === classCode) {
      setSubjects([]);
      setSelectedClass(null);
      return;
    }
    axios
      .post(`${API_BASE_URL}/api/subjects`, { boardCode: selectedBoard, classCode })
      .then((response) => {
        setSubjects(response.data);
        setSelectedClass(classCode);
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error);
      });
  };

  const handleSubjectClick = (subjectCode) => {

    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const suCode = userDetails?.SUCode; 
    if (suCode && selectedBoard && selectedClass) {
      const boardCode = selectedBoard;
      const classCode = selectedClass;
      navigate(`/chapterdetails/${suCode}/${boardCode}/${classCode}/${subjectCode}`);
    } else {
      console.error("Error: SUCode, BoardCode, or ClassCode is missing.");
    }
  };


  const fetchCentreDetails = async () => {
    setError('');
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/study-centre-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok) {
        setCentreDetails(data.data);
      } else {
        setError(data.error || 'Failed to fetch centre details.');
      }
    } catch (err) {
      console.error(err);
      setError('Network error, please try again.');
    }
    setLoading(false);
  };

  const handleToggle = () => {
    setShowTable(!showTable);  

    if (!showTable) {
      fetchCentreDetails(); 
    }
  };
  

  return (
  <div className="Total-Container">
    <div className="Left-Side">
      <div className="board-list">
        {boards.length > 0 ? (
          boards.map((board) => (
            <div
              key={board.BoardCode}
              className={`board-item ${selectedBoard === board.BoardCode ? "selected" : ""}`}
              onClick={() => fetchClasses(board.BoardCode)}
            >
              {board.BoardName}
              {selectedBoard === board.BoardCode && (
                <div className="class-list">
                  {classes.length > 0 ? (
                    classes.map((classItem) => (
                      <div
                        key={classItem.ClassCode}
                        className="class-container"
                        onClick={(e) => {
                          e.stopPropagation();
                          fetchSubjects(classItem.ClassCode);
                        }}
                      >
                        {classItem.ClassName}
                        {selectedClass === classItem.ClassCode && (
                          <div className="subject-list">
                            {subjects.length > 0 ? (
                              subjects.map((subject) => (
                                <div
                                  key={subject.SubjectCode}
                                  className="subject-container"
                                  onClick={() => handleSubjectClick(subject.SubjectCode)} // Handle subject click
                                >
                                  {subject.SubjectName}
                                </div>
                              ))
                            ) : (
                              <p>No subjects available.</p>
                            )}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No classes available.</p>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No boards found.</p>
        )}
      
      <div className="Left-Side-Under" onClick={handleToggle}>
        Centre
      </div>
      </div>
      {/* Display Table */}
      <div className="right-Side">
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {showTable && centreDetails.length > 0 ? (
          <table border="1" className="rounded-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Centre Code</th>
                <th>Centre Name</th>
                <th>Class Number</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {centreDetails.map((centre, index) => (
                <tr key={centre.SUCode}>
                  <td>{index + 1}</td>
                  <td>{centre.CentreCode}</td>
                  <td>{centre.CentreName}</td>
                  <td>{centre.ClassNum}</td>
                  <td>{centre.StartTime}</td>
                  <td>{centre.EndTime}</td>
                  <td>
                  {centre.Active === 'Y' ? (
                    <span role="img" aria-label="checkmark">✔</span>
                  ) : (
                    <span role="img" aria-label="cross">❌</span>
                  )}
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          !loading 
        )}
      </div>
    </div>

    <div className="Right-Side">
      <p className="Name"> Teacher Name : {teacherName}</p> 
    </div>

  </div>
  );
};

export default Class;
