import React, { useState } from "react";

const Sidebar = ({ selectedMenu, setSelectedMenu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <ul>
        <li
          title="Profile"
          onClick={() => setSelectedMenu("Profile")}
          className={selectedMenu === "Profile" ? "active" : ""}
        >
          <i className="bi bi-person"></i>
          {!isCollapsed && <span>Profile</span>}
        </li>
        <li
          title="Class"
          onClick={() => setSelectedMenu("Class")}
          className={selectedMenu === "Class" ? "active" : ""}
        >
          <i className="bi bi-house-door"></i>
          {!isCollapsed && <span>Class</span>}
        </li>
        <li
          title="Home Work"
          onClick={() => setSelectedMenu("Home Work")}
          className={selectedMenu === "Home Work" ? "active" : ""}
        >
          <i className="bi bi-book"></i>
          {!isCollapsed && <span>Home Work</span>}
        </li>
        <li
          title="Doubt"
          onClick={() => setSelectedMenu("Doubt")}
          className={selectedMenu === "Doubt" ? "active" : ""}
        >
          <i className="bi bi-question-circle"></i>
          {!isCollapsed && <span>Doubt</span>}
        </li>
        <li
          title="Calendar"
          onClick={() => setSelectedMenu("Calendar")}
          className={selectedMenu === "Calendar" ? "active" : ""}
        >
          <i className="bi bi-calendar"></i>
          {!isCollapsed && <span>Calendar</span>}
        </li>
        <li
          title="Assignment"
          onClick={() => setSelectedMenu("Assignment")}
          className={selectedMenu === "Assignment" ? "active" : ""}
        >
          <i className="bi bi-file-earmark-check"></i>
          {!isCollapsed && <span>Assignment</span>}
        </li>
        <li
          title="Exam"
          onClick={() => setSelectedMenu("Exam")}
          className={selectedMenu === "Exam" ? "active" : ""}
        >
          <i className="bi bi-journal-check"></i>
          {!isCollapsed && <span>Exam</span>}
        </li>
        <li
          title="Leave Application"
          onClick={() => setSelectedMenu("Leave Application")}
          className={selectedMenu === "Leave Application" ? "active" : ""}
        >
          <i className="bi bi-file-earmark-text"></i>
          {!isCollapsed && <span>Leave Application</span>}
        </li>
      </ul>
      {/* Collapse/Expand Button */}
      <div className="collapse-button" onClick={toggleSidebar}>
        <img
          src="https://www.svgrepo.com/show/500770/arrowleft.svg"
          alt="Collapse"
          style={{
            transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate for collapse/expand
            width: '30px',
            height: '30px',
            transition: 'transform 0.3s ease',
          }}
        />
      </div>
    </div>
  );
};

export default Sidebar;
