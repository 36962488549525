import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../Css(All)/TeacherHome.css';
import Sidebar from '../Teacher Part/Sidebar';
import Content from '../Teacher Part/Content';

const Navbar = ({ selectedMenu, teacherName }) => {
  return (
    <nav className="navbar">
      <div className="navbar-center">
        <span className="navbar-item">{selectedMenu}</span>
      </div>
      <div className="navbar-end">
        <span>Hello, {teacherName}</span>
      </div>
    </nav>
  );
};

const TeacherHome = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const teacherName = userDetails ? userDetails.TeacherName : 'Teacher'; 

  const [selectedMenu, setSelectedMenu] = useState('');

  return (
    <div>
      <Navbar selectedMenu={selectedMenu} teacherName={teacherName} />
      <div style={{ display: 'flex' }}>
        <Sidebar setSelectedMenu={setSelectedMenu} />
        <Content selectedMenu={selectedMenu} />
      </div>
    </div>
  );
};

export default TeacherHome;
