import React from 'react';

const HomeWork = () => {
  return (
    <div>
      <h4>Home Work Content</h4>
      <p>This section contains homework assigned to students.</p>
    </div>
  );
};

export default HomeWork;
