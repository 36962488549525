import React from "react";
import RouteConfigs from "./Routing Components/RouteConfigs";

const App = () => {
  
  return(
    <div className="App">
      <RouteConfigs/>
    </div>
  )
}
export default App;
