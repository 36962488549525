import React, { useState } from 'react';
import Calendar from 'react-calendar';
import '../Css(All)/Calendar.css';


const TeacherCalendar = () => {
  const [date, setDate] = useState(new Date()); 

  const handleDateChange = (newDate) => {
    setDate(newDate); 
  };

  return (
    <div className="calendar-container">
      <Calendar 
        onChange={handleDateChange} 
        value={date} 
      />

      <div className="selected-date">
        <p><strong>Selected Date:</strong> {date.toDateString()}</p>
      </div>
    </div>
  );
};

export default TeacherCalendar;
