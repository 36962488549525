import React, { useState, useEffect } from "react";
import axios from "axios";
import '../Css(All)/Profile.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Profile = () => {
  const [photo, setPhoto] = useState(null);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    mobile: "",
    whatsapp: "",
    active: true,
  });
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [educationDetails, setEducationDetails] = useState({
    lineid: "",
    certificate: "",
    board: "",
    year: "",
    marks: "",
    grade: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [showExperienceForm, setShowExperienceForm] = useState(false);
  const [experienceData, setExperienceData] = useState([]);
  const [formData, setFormData] = useState({
    organization: '',
    fromDate: '',
    toDate: '',
    designation: '',
    responsibilities: '',
    remarks: ''
  });

  const [showSubjectForm, setShowSubjectForm] = useState(false);
  const [subjectDetails, setSubjectDetails] = useState({
    board: "",
    class: "",
    subject: "",
  });
  const [subjectList, setSubjectList] = useState([]);

  const [showCentreForm, setShowCentreForm] = useState(false);
  const [centreDetails, setCentreDetails] = useState({
    centre: '',
  });
  const [centreList, setCentreList] = useState([]);

  const [showKYCForm, setShowKYCForm] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [Dataform, setDataForm] = useState({
    docType: "",
    docNumber: "",
    attachment: null,
    remarks: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);




  const teacherDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const suCode = teacherDetails?.SUCode;
  const teacherId = teacherDetails?.TeacherID;
  const emailID = teacherDetails?.EmailID;
  const updatedBy = teacherDetails?.CreatedBy;


  useEffect(() => {
    if (emailID) {
      axios
        .post(`${API_BASE_URL}/api/teacher-profile`, { EmailID: emailID })
        .then((response) => {
          const { TeacherName, EmailID, Mobile, WhatsApp, Active, TeacherImage } = response.data;
          setProfileData({
            name: TeacherName,
            email: EmailID,
            mobile: Mobile,
            whatsapp: WhatsApp,
            active: Active,
          });
          if (TeacherImage) {
            setPhoto(`${API_BASE_URL}${TeacherImage}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data", error);
        });
    } else {
      console.error("No email ID found in session storage");
    }
  }, [emailID]);

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("suCode", suCode);
    formData.append("teacherId", teacherId);
    formData.append("createdBy", emailID);
    formData.append("updatedBy", updatedBy);

    if (photo instanceof File) {
      formData.append("Image", photo);
    } else {
      alert("Please upload a valid image file.");
      return;
    }

    axios
      .post(`${API_BASE_URL}/api/update-teacher-profile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { RespCode, RespMsg, TeacherImage } = response.data;
        if (RespCode === "0") {
          alert(RespMsg);
          setPhoto(`${API_BASE_URL}${TeacherImage}`);
        } else {
          alert(RespMsg);
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        alert("Failed to update the profile. Please try again.");
      });
  };


  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setPhoto(file);
    } else {
      alert("Please select a valid image file.");
    }
  };


  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };





  // Fetch education details on component load
  const fetchEducationDetails = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/get-education`, {
        TeacherID: teacherId,
      });

      if (response.status === 200 && Array.isArray(response.data)) {
        setEducationList(response.data);
      } else {
        console.error("Invalid response data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching education details:", error);
    }
  };

  useEffect(() => {
    fetchEducationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleEducationForm = () => {
    setShowEducationForm(!showEducationForm);
  };

  const handleEditClick = (index) => {
    const education = educationList[index];

    // Populate the form with the selected entry's details
    setEducationDetails({
      lineid: education.lineid,
      certificate: education.StudyName,
      board: education.StudyBoard,
      year: education.PassYear,
      marks: education.Marks,
      grade: education.Grade,
    });
    setIsEditing(true);
    setEditIndex(index);
    setShowEducationForm(true);
  };


  const handleEducationInputChange = (e) => {
    const { name, value } = e.target;
    setEducationDetails({
      ...educationDetails,
      [name]: value,
    });
  };

  const handleEducationFormSubmit = async (e) => {
    e.preventDefault();

    // Validate session storage data
    if (!suCode || !teacherId || !emailID) {
      alert("Teacher details are missing in session storage!");
      return;
    }

    const newEducation = {
      suCode,
      teacherId,
      lineid: educationDetails.lineid,
      studyName: educationDetails.certificate,
      studyBoard: educationDetails.board,
      passYear: educationDetails.year.toString(),
      marks: educationDetails.marks.toString(),
      grade: educationDetails.grade,
      createdBy: emailID,
      updatedBy: updatedBy,
    };
    try {
      let response;
      if (isEditing) {
        // Update existing education details
        response = await axios.post(`${API_BASE_URL}/api/update-education`, newEducation);
        if (response.status === 200) {
          alert("Education details updated successfully!");
          setEducationList((prevList) =>
            prevList.map((item, i) =>
              i === editIndex
                ? {
                  ...item,
                  StudyName: educationDetails.certificate,
                  StudyBoard: educationDetails.board,
                  PassYear: educationDetails.year,
                  Marks: educationDetails.marks,
                  Grade: educationDetails.grade,
                }
                : item
            )
          );
        } else {
          alert(response.data?.RespMsg || "Failed to update education details!");
        }
      } else {
        // Handle adding new education if required
        response = await axios.post(`${API_BASE_URL}/api/add-education`, newEducation);

        if (response.status === 201) {
          alert("Education details added successfully!");
          setEducationList((prevList) => [
            ...prevList,
            {
              StudyName: educationDetails.certificate,
              StudyBoard: educationDetails.board,
              PassYear: educationDetails.year,
              Marks: educationDetails.marks,
              Grade: educationDetails.grade,
            },
          ]);
        }
      }

      // Reset the form and states
      setEducationDetails({
        certificate: "",
        board: "",
        year: "",
        marks: "",
        grade: "",
      });
      setIsEditing(false);
      setEditIndex(null);
      setShowEducationForm(false);
    } catch (error) {
      console.error("Error saving education details:", error);
      alert(error.response?.data?.RespMsg || "Failed to save education details!");
    }
  };





  // Toggle experience form visibility
  const toggleExperienceForm = () => {
    setShowExperienceForm(!showExperienceForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const fetchExperienceDetails = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/get-experience`, {
        TeacherID: teacherId,
      });

      if (response.status === 200 && Array.isArray(response.data)) {
        setExperienceData(response.data);
      } else {
        console.error("Invalid response data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching experience details:", error);
    }
  };

  const handleExperienceFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/api/add-experience`, {
        suCode,
        teacherId,
        Organisation: formData.organization,
        FromDate: formData.fromDate,
        ToDate: formData.toDate,
        Designation: formData.designation,
        Responsibilities: formData.responsibilities,
        Remarks: formData.remarks,
        createdBy: emailID,
      });


      if (response.status === 200) {
        // Add new experience to the table
        setExperienceData((prevExperienceData) => [
          ...prevExperienceData,
          response.data,
        ]);

        // Reset form
        setFormData({
          organization: "",
          fromDate: "",
          toDate: "",
          designation: "",
          responsibilities: "",
          remarks: "",
        });
      } else {
        console.error("Error adding experience:", response.data);
      }
    } catch (error) {
      console.error("Error submitting experience details:", error);
    }
  };
  useEffect(() => {
    fetchExperienceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);






  // Toggle Subject form visibility
  const toggleSubjectForm = () => {
    setShowSubjectForm(!showSubjectForm);
  };

  const handleSubjectInputChange = (e) => {
    const { name, value } = e.target;
    setSubjectDetails({
      ...subjectDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/get-teacher-subjects?teacherId=${teacherId}`
        );
        setSubjectList(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    if (teacherId) {
      fetchSubjects();
    }
  }, [teacherId]);

  const handleSubjectFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/api/add-teacher-subject`, {
        teacherId,
        suCode,
        board: subjectDetails.board,
        class: subjectDetails.class,
        subject: subjectDetails.subject,
        createdBy: emailID,
      });

      // Reset form inputs
      setSubjectDetails({
        board: "",
        class: "",
        subject: "",
      });

      // Refresh the subject list
      const response = await axios.post(
        `${API_BASE_URL}/api/get-teacher-subjects?teacherId=${teacherId}`
      );
      setSubjectList(response.data);
      setShowSubjectForm(false);
    } catch (error) {
      console.error("Error adding subject:", error);
    }
  };





  // Toggle Centre form visibility
  const toggleCentreForm = () => {
    setShowCentreForm(!showCentreForm);
  };

  const handleCentreInputChange = (e) => {
    const { name, value } = e.target;
    setCentreDetails({
      ...centreDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchCentreDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCentreDetails = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/get-centre`, { teacherId });
      if (Array.isArray(response.data)) {
        setCentreList(response.data);
      } else {
        console.error("Response data is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching centre details:", error);
    }
  };

  const handleCentreFormSubmit = async (e) => {
    e.preventDefault();

    if (!centreDetails.centre) {
      alert("Centre Code is required!");
      return;
    }

    const newCentre = {
      suCode,
      teacherId,
      centreCode: centreDetails.centre,
      createdBy: emailID,
      approvalStatus: 'N',
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/add-centre`, newCentre);
      if (response.status === 201) {
        alert("Centre details added successfully!");
        setCentreDetails({ centre: '' });
        fetchCentreDetails();
      }
    } catch (error) {
      console.error("Error adding Centre details:", error);
      alert("Failed to add Centre details!");
    }
  };





  // Toggle KYC form visibility
  const toggleKYCForm = () => {
    setShowKYCForm(!showKYCForm);
  };

  const fetchKYCData = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/get-kyc`, { teacherId });

      if (response.status === 200 && Array.isArray(response.data)) {
        setKycData(
          response.data.map((item) => ({
            ...item,
            attachment: `${API_BASE_URL}${item.FilePath}`,
          }))
        );
      } else {
        console.error("Invalid response data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching KYC data:", error);
    }
  };



  useEffect(() => {
    fetchKYCData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherId]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDataForm({
      ...Dataform,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setDataForm({ ...Dataform, attachment: e.target.files[0] });
  };

  const handleKYCSubmit = async (e) => {
    e.preventDefault();

    if (Dataform.docType && Dataform.docNumber && Dataform.attachment) {
      const formData = new FormData();
      formData.append("suCode", suCode);
      formData.append("teacherId", teacherId);
      formData.append("docType", Dataform.docType);
      formData.append("docNumber", Dataform.docNumber);
      formData.append("remarks", Dataform.remarks);
      formData.append("attachment", Dataform.attachment);
      formData.append("createdBy", emailID);

      try {
        const response = await fetch(`${API_BASE_URL}/api/insertTeacherKYC`, {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();

        if (response.ok) {
          alert(result.RespMsg);
          fetchKYCData();

          const filePath = result.FilePath;
          if (filePath) {
          }
        } else {
          alert('Error submitting KYC');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    } else {
      alert("Please fill in all fields");
    }
  };


  const fetchFile = async (docType, docNumber) => {
    try {
      // Check if the same file is clicked again (to close the modal)
      if (selectedFileData && selectedFileData.docType === docType && selectedFileData.docNumber === docNumber) {
        setShowModal(false); // Close the modal
        setSelectedFileData(null); // Clear the selected file
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/api/get-kyc-file`, { docType, docNumber });

      if (response.status === 200 && response.data.fileData) {
        // Convert base64 to byte data (Blob)
        const base64Data = response.data.fileData;
        const byteCharacters = atob(base64Data.split(',')[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: response.data.fileType });
        const fileURL = URL.createObjectURL(blob);

        setSelectedFileData({
          fileURL,
          docType,
          docNumber
        });
        setShowModal(true);
      } else {
        console.error("Error fetching file");
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };






  return (
    <div className="Profile-container">
      <form className="profile-form"
        onSubmit={handleProfileSubmit}>
        <div className="form-left">
          <div className="row">
            <div className="Column">
              <div className="Form-Group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={profileData.name}
                  onChange={handleProfileChange}
                  placeholder="Your name"
                />
              </div>
            </div>
            <div className="Column">
              <div className="Form-Group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleProfileChange}
                  placeholder="Your email"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="Column">
              <div className="Form-Group">
                <label htmlFor="mobile">Mobile No.:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={profileData.mobile}
                  onChange={handleProfileChange}
                  placeholder="Your mobile number"
                />
              </div>
            </div>
            <div className="Column">
              <div className="Form-Group">
                <label htmlFor="whatsapp">WhatsApp:</label>
                <input
                  type="tel"
                  id="whatsapp"
                  name="whatsapp"
                  value={profileData.whatsapp}
                  onChange={handleProfileChange}
                  placeholder="Your WhatsApp number"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="Column">
              <div className="Form-Group active-group">
                <label htmlFor="active">Active:</label>
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  checked={profileData.active}
                  onChange={() => setProfileData(prevData => ({ ...prevData, active: !prevData.active }))}
                />
              </div>
              <div className="update-button">
                <button type="submit" className="SUBMIT-button">Update</button>
              </div>
            </div>
          </div>
        </div>

        <div className="form-right">
          <div className="photo-upload">
            <div className="photo-preview">
              {photo ? (
                photo instanceof File ? (
                  <img src={URL.createObjectURL(photo)} alt="Profile Preview" />
                ) : (
                  <img src={photo} alt="Profile" />
                )
              ) : (
                <div className="photo-placeholder">No Photo</div>
              )}
            </div>
            <label htmlFor="photo-upload" className="edit-icon">
              &#9998; {/* Edit icon */}
            </label>
            <input
              type="file"
              id="photo-upload"
              accept="image/*"
              onChange={handlePhotoUpload}
              className="photo-input"
            />
          </div>
        </div>
      </form>


      <div className="Panels-container">
        {/* Education Panel */}
        <div className="panel">
          <div className="panel-header" onClick={toggleEducationForm}>
            <span>Education</span>
            <button className="toggle-button">{showEducationForm ? "-" : "+"}</button>
          </div>

          {showEducationForm && educationList.length > 0 && (
            <div className="Table">
              <table>
                <thead>
                  <tr>
                    <th>Line ID</th>
                    <th>Certificate</th>
                    <th>Board</th>
                    <th>Year</th>
                    <th>Marks</th>
                    <th>Grade</th>
                    <th>Edit/Update</th>
                  </tr>
                </thead>
                <tbody>
                  {educationList.map((education, index) => (
                    <tr key={index}>
                      <td>{education.lineid}</td>
                      <td>{education.StudyName}</td>
                      <td>{education.StudyBoard}</td>
                      <td>{education.PassYear}</td>
                      <td>{education.Marks}</td>
                      <td>{education.Grade}</td>
                      <td><button className="Edit-button" onClick={() => handleEditClick(index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path d="M 16.9375 1.0625 L 3.875 14.125 L 1.0742188 22.925781 L 9.875 20.125 L 22.9375 7.0625 C 22.9375 7.0625 22.8375 4.9615 20.9375 3.0625 C 19.0375 1.1625 16.9375 1.0625 16.9375 1.0625 z M 17.3125 2.6875 C 18.3845 2.8915 19.237984 3.3456094 19.896484 4.0214844 C 20.554984 4.6973594 21.0185 5.595 21.3125 6.6875 L 19.5 8.5 L 15.5 4.5 L 16.9375 3.0625 L 17.3125 2.6875 z M 4.9785156 15.126953 C 4.990338 15.129931 6.1809555 15.430955 7.375 16.625 C 8.675 17.825 8.875 18.925781 8.875 18.925781 L 8.9179688 18.976562 L 5.3691406 20.119141 L 3.8730469 18.623047 L 4.9785156 15.126953 z"></path>
                        </svg>
                      </button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Education Form */}
          {showEducationForm && (
            <div className="panel-container">
              <form className="Panel" onSubmit={handleEducationFormSubmit}>
                <div className="row">
                  <div className="Column">
                    <input
                      type="hidden"
                      id='edulineid'
                      name="edulineid"
                      value={educationDetails.lineid}
                      onChange={handleEducationInputChange}
                    >
                    </input>
                    <div className="form-group">
                      <label htmlFor="certificate">Certificate:</label>
                      <input
                        type="text"
                        id="certificate"
                        name="certificate"
                        className="form-control"
                        value={educationDetails.certificate}
                        onChange={handleEducationInputChange}
                      />
                    </div>
                  </div>
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="board">Board:</label>
                      <input
                        type="text"
                        id="board"
                        name="board"
                        className="form-control"
                        value={educationDetails.board}
                        onChange={handleEducationInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="year">Year:</label>
                      <input
                        type="text"
                        id="year"
                        name="year"
                        className="form-control"
                        value={educationDetails.year}
                        onChange={handleEducationInputChange}
                      />
                    </div>
                  </div>
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="marks">Marks:</label>
                      <input
                        type="text"
                        id="marks"
                        name="marks"
                        className="form-control"
                        value={educationDetails.marks}
                        onChange={handleEducationInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="grade">Grade:</label>
                      <input
                        type="text"
                        id="grade"
                        name="grade"
                        className="form-control"
                        value={educationDetails.grade}
                        onChange={handleEducationInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="Add-button">
                  <button type="submit" className="SUBMIT-button">{isEditing ? "Update" : "Add"}</button>
                  {isEditing && (
                    <button
                      type="button"
                      className="SUBMIT-button"
                      onClick={() => {
                        setEducationDetails({
                          lineid: "",
                          certificate: "",
                          board: "",
                          year: "",
                          marks: "",
                          grade: "",
                        });
                        setIsEditing(false);
                        setEditIndex(null);
                      }}
                    >
                      Close
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>



        {/* Experience Panel */}
        <div className="panel">
          <div className="panel-header" onClick={toggleExperienceForm}>
            <span>Experience</span>
            <button className="toggle-button">{showExperienceForm ? "-" : "+"}</button>
          </div>
          {/* Table to display experience data */}
          {showExperienceForm && experienceData.length > 0 && (
            <div className="Table">
              <table>
                <thead>
                  <tr>
                    <th>Organization</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Designation</th>
                    <th>Responsibilities</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {experienceData.map((experience, index) => (
                    <tr key={index}>
                      <td>{experience.Organisation}</td>
                      <td>{experience.FromDate}</td>
                      <td>{experience.ToDate}</td>
                      <td>{experience.Designation}</td>
                      <td>{experience.Responsibilities}</td>
                      <td>{experience.Remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {showExperienceForm && (
            <div className="panel-container">
              <form className="Panel" onSubmit={handleExperienceFormSubmit}>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="organization">Organization:</label>
                      <input
                        type="text"
                        id="organization"
                        name="organization"
                        className="form-control"
                        value={formData.organization}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="fromDate">From Date:</label>
                      <input
                        type="date"
                        id="fromDate"
                        name="fromDate"
                        className="form-control"
                        value={formData.fromDate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="toDate">To Date:</label>
                      <input
                        type="date"
                        id="toDate"
                        name="toDate"
                        className="form-control"
                        value={formData.toDate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="designation">Designation:</label>
                      <input
                        type="text"
                        id="designation"
                        name="designation"
                        className="form-control"
                        value={formData.designation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="responsibilities">Responsibilities:</label>
                      <input
                        id="responsibilities"
                        name="responsibilities"
                        className="form-control"
                        value={formData.responsibilities}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="remarks">Remarks:</label>
                        <textarea
                          id="remarks"
                          name="remarks"
                          className="form-control"
                          value={formData.remarks}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="Add-button">
                  <div className="Column">
                    <button type="submit" className="SUBMIT-button">Add Experience</button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>


        {/* Subject Prefrance*/}
        <div className="panel">
          <div className="panel-header" onClick={toggleSubjectForm}>
            <span>Subject</span>
            <button className="toggle-button">{showSubjectForm ? "-" : "+"}</button>
          </div>

          {showSubjectForm && subjectList.length > 0 && (
            <div className="Table">
              <table>
                <thead>
                  <tr>
                    <th>Board</th>
                    <th>Class</th>
                    <th>Subject</th>
                  </tr>
                </thead>
                <tbody>
                  {subjectList.length > 0 ? (
                    subjectList.map((subject, index) => (
                      <tr key={index}>
                        <td>{subject.BoardCode}</td>
                        <td>{subject.ClassCode}</td>
                        <td>{subject.SubjectCode}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No subjects found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {showSubjectForm && (
            <div className="panel-container">
              <form className="Panel" onSubmit={handleSubjectFormSubmit}>
                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="board">Board:</label>
                      <input
                        type="text"
                        id="board"
                        name="board"
                        value={subjectDetails.board}
                        onChange={handleSubjectInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="class">Class:</label>
                      <input
                        type="text"
                        id="class"
                        name="class"
                        value={subjectDetails.class}
                        onChange={handleSubjectInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="subject">Subject:</label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={subjectDetails.subject}
                        onChange={handleSubjectInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <div className="Add-button">
                        <button type="submit" className="SUBMIT-button">Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>


        {/* Centre Prefrance*/}
        <div className="panel">
          <div className="panel-header" onClick={toggleCentreForm}>
            <span>Centre</span>
            <button className="toggle-button">{showCentreForm ? "-" : "+"}</button>
          </div>

          {showCentreForm && centreList.length > 0 && (
            <div className="Table">
              <table>
                <thead>
                  <tr>
                    <th>Centre Code</th>
                    <th>Teacher ID</th>
                    <th>Created By</th>
                    <th>Approval Status</th>
                  </tr>
                </thead>
                <tbody>
                  {centreList.map((centre, index) => (
                    <tr key={index}>
                      <td>{centre.CentreCode}</td>
                      <td>{centre.TeacherID}</td>
                      <td>{centre.CreatedBy}</td>
                      <td>{centre.ApprovalStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {showCentreForm && (
            <div className="panel-container">
              <form className="Panel" onSubmit={handleCentreFormSubmit}>
                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="centre">Centre Code:</label>
                      <input type="text" id="centre" name="centre" className="form-control"
                        value={centreDetails.centre}
                        onChange={handleCentreInputChange} />
                    </div>
                  </div>
                </div>
                <div className="Add-button">
                  <button type="submit" className="SUBMIT-button">Add</button>
                </div>
              </form>
            </div>
          )}
        </div>


        {/* KYC Panel */}
        <div className="panel">
          <div className="panel-header" onClick={toggleKYCForm}>
            <span>KYC</span>
            <button className="toggle-button">{showKYCForm ? "-" : "+"}</button>
          </div>

          {showKYCForm && (
            <>
              {kycData.length > 0 ? (
                <div className="Table">
                  <table>
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th>Document No.</th>
                        <th>Attachment</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kycData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.docType}</td>
                          <td>{data.docNumber}</td>
                          <td>
                            <div>
                              <button onClick={() => fetchFile(data.docType, data.docNumber)}>
                                {selectedFileData && selectedFileData.docType === data.docType && selectedFileData.docNumber === data.docNumber && showModal
                                  ? 'Close File'
                                  : 'View File'}
                              </button>

                              {showModal && selectedFileData && selectedFileData.docType === data.docType && selectedFileData.docNumber === data.docNumber && (
                                <div className="modal-content">
                                  <img
                                    src={selectedFileData.fileURL}
                                    alt="KYC File"
                                    style={{
                                      width: '100%',
                                      height: 'auto',
                                      maxWidth: '500px',
                                      maxHeight: '500px',
                                      objectFit: 'contain'
                                    }}
                                  />
                                  <a href={selectedFileData.fileURL} download="kyc-file.jpg">
                                    Download
                                  </a>
                                </div>
                              )}
                            </div>
                          </td>


                          <td>{data.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No KYC data found</p>
              )}

              <div className="panel-container">
                <form className="Panel" onSubmit={handleKYCSubmit}>
                  <div className="row">
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="docType">Document Type:</label>
                        <select
                          id="docType"
                          name="docType"
                          className="form-control"
                          value={Dataform.docType}
                          onChange={handleChangeInput}
                        >
                          <option value="">-- Select --</option>
                          <option value="PAN">PAN</option>
                          <option value="Aadhar">Aadhar</option>
                          <option value="Driving License">Driving License</option>
                          <option value="Passport">Passport</option>
                          <option value="Voter ID">Voter ID</option>
                        </select>
                      </div>
                    </div>

                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="docNumber">Document No. :</label>
                        <input
                          type="text"
                          id="docNumber"
                          name="docNumber"
                          className="form-control"
                          value={Dataform.docNumber}
                          onChange={handleChangeInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="attachment">Attachment:</label>
                        <input
                          type="file"
                          id="attachment"
                          name="attachment"
                          className="form-control"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="remarks">Remarks:</label>
                        <textarea
                          id="remarks"
                          name="remarks"
                          className="form-control"
                          value={Dataform.remarks}
                          onChange={handleChangeInput}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="Column">
                      <div className="form-group">
                        <div className="Add-button">
                          <button type="submit" className="SUBMIT-button">
                            Add KYC
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>


      </div>
    </div>
  );
};

export default Profile;
